import { createApp } from 'vue'

require('vue2-animate/dist/vue2-animate.min.css')

import App from './App.vue'
import Contact from './Contact.vue'
import HardwareRequest from './HardwareRequest.vue'
import HardwareRentRequest from './HardwareRentRequest.vue'
import ProjectsSlider from './ProjectsSlider.vue'

const apps = []

if (document.getElementById('app-global')) {
  apps.push({
    view: App,
    container: document.getElementById('app-global')
  })
}

if (document.getElementById('app-contact')) {
  apps.push({
    view: Contact,
    container: document.getElementById('app-contact')
  })
}

if (document.getElementById('app-hardware-request')) {
  apps.push({
    view: HardwareRequest,
    container: document.getElementById('app-hardware-request')
  })
}

if (document.getElementById('app-hardware-rent-request')) {
  apps.push({
    view: HardwareRentRequest,
    container: document.getElementById('app-hardware-rent-request')
  })
}

if (document.getElementById('app-projects-slider')) {
  apps.push({
    view: ProjectsSlider,
    container: document.getElementById('app-projects-slider')
  })
}

apps.forEach((app) => {
  const instance = createApp(app.view)
  instance.mount(app.container)
})
