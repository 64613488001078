<script setup>
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

const projects = [
  {
    client: 'DLR',
    title: 'Interaktive Auswertung großer Datensätze',
    img: '/static/img/projects/dlr.webp',
    url: '/projects/dlr/'
  },
  {
    client: 'Belimo Holding AG',
    title: 'Digitales Markenerlebnis - Kunden und Vertrieb begeistern',
    img: '/static/img/projects/belimo.webp',
    url: '/projects/belimo/'
  },
  {
    client: 'Rehau',
    title: 'Digitale Produktinszenierung',
    img: '/static/img/projects/rehau.webp',
    url: '/projects/rehau/'
  },
  {
    client: 'Strategiedialog Automobilwirtschaft BW',
    title: 'Schlüsseltechnologien der Mobilität interaktiv',
    img: '/static/img/projects/tkbw.webp',
    url: '/projects/tkbw/'
  },
  {
    client: 'Landesagentur für neue Mobilitätslösungen und Automotive Baden-Württemberg',
    title: 'Innovative Darstellung von Clusterprojekten',
    img: '/static/img/projects/cluster-elektromobilitaet.webp',
    url: '/projects/cluster-elektromobilitaet/'
  },
  {
    client: 'ZIEGERT GmbH',
    title: 'Digitales Sales Tool für Immobilien - Riva Living',
    img: '/static/img/projects/riva-living.webp',
    url: '/projects/riva-living/'
  },
  {
    client: 'KITO',
    title: 'Praxisnaher Produkteinsatz mit Hilfe einer WebApp',
    img: '/static/img/projects/kito.webp',
    url: '/projects/kito/'
  },
  {
    client: 'Sixt',
    title: 'Sixt macht die Customer Journey zu einem Erlebnis',
    img: '/static/img/projects/sixt.webp',
    url: '/projects/sixt/'
  },
  {
    client: 'LOGICHECK',
    title: 'LOGICHECK Campus: Eine Erlebniswelt für komplexe Dienstleistungen',
    img: '/static/img/projects/logicheck.webp',
    url: '/projects/logicheck/'
  },
  {
    client: 'Schaller',
    title: 'Moderne WebApp veranschaulicht die Vorteile der Schaller-Produkte',
    img: '/static/img/projects/schaller.webp',
    url: '/projects/schaller/'
  },
  {
    client: 'Wettermuseum',
    title: 'Klimawandel interaktiv erleben: Das Wettermuseum macht komplexe Zusammenhänge greifbar',
    img: '/static/img/projects/wettermuseum1.webp',
    url: '/projects/wettermuseum/'
  },
].sort((a, b) => 0.5 - Math.random())

const breakpoints = {
  1024: {
    itemsToShow: 2,
  },
}
</script>


<template>
  <div class="slider">
    <carousel
        :items-to-show="1.25"
        :wrapAround="true"
        :breakpoints="breakpoints">
      <slide
          v-for="(project, idx) in projects"
          :key="idx"
          class="slide">
        <a
            :href="project.url"
            class="project">
          <img
              :src="project.img"
              :alt="project.title">
          <div class="mask" />
          <div class="description">
            <h3>
              <small>{{ project.client }}</small>
              {{ project.title }} →
            </h3>
          </div>
        </a>
      </slide>
      <template #addons>
        <navigation />
      </template>
    </carousel>
  </div>
</template>


<style lang="scss">
@use '../assets/scss/common/config';
@use '../assets/scss/common/mixins';

.slider {
  width: 100vw;
  margin: 2rem 0 -1.5rem 0;

  .slide {
    padding: 1.5rem 0.5rem;

    .project {
      width: 100%;
      color: #000;
      border-radius: 0.5rem;
      overflow: hidden;
      box-shadow: 0 0 1.5rem rgba($color: #000, $alpha: 0.5);
      position: relative;
      border: 1px solid rgba($color: #fff, $alpha: 0.25);

      img {
        display: block;
        pointer-events: none;
      }

      .mask {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all linear 750ms;
        background: linear-gradient(135deg, rgba(#000, 0.6) 0%, rgba(#150c40, 0.85) 100%);
        pointer-events: none;
      }

      .description {
        position: absolute;
        width: calc(100% - 1rem);
        left: 0;
        bottom: 0;
        background-color: rgba($color: #5000f1, $alpha: 0.85);
        padding: 1rem;
        transition: all ease-in-out 300ms;
        transform: translateY(calc(100% + 0.5rem));
        margin: 0.5rem;
        border-radius: 0.5rem;
        pointer-events: none;
        font-size: 0.5rem;
        text-align: left;

        @include mixins.breakpoint('md') {
          font-size: 0.85rem;
          padding: 2rem;
        }

        h3 {
          color: #fff;
          font-weight: 600;
          font-size: 1rem;
          margin: 0;

          @include mixins.breakpoint('sm') {
            font-size: 1rem;
          }

          @include mixins.breakpoint('md') {
            font-size: 1.25rem;
          }

          @include mixins.breakpoint('lg') {
            font-size: 1.5rem;
          }

          small {
            display: block;
            font-weight: 200;
          }
        }
      }

      &:hover {
        .mask {
          opacity: 1;
        }

        .description {
          transform: translateY(0%);
        }
      }
    }
  }

  .carousel__prev,
  .carousel__next {
    z-index: 100;
    padding: 1.5rem 2.5rem;
    left: 2.5rem;
    border-radius: 1rem;
    background-color: #535b65;
    transition: ease-in-out background-color 250ms;

    .carousel__icon {
      z-index: 10;
      fill: #fff !important;
      position: absolute;
    }

    &::before {
      content: '';
      position: absolute;
      top: -0.375rem;
      left: -0.375rem;
      width: calc(100% + 0.75rem);
      height: calc(100% + 0.75rem);
      border-radius: 1.25rem;
      border: 1px solid rgba($color: #fff, $alpha: 0.25);
    }

    &:hover {
      background-color: #5000f1;
    }
  }

  .carousel__next {
    left: unset;
    right: 2.5rem;
  }
}
</style>
