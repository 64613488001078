<script setup>
import { ref, computed, onMounted } from 'vue'

const questions = ref([
  {
    question: 'Welcher Einsatz ist vorgesehen? (Mehrfachauswahl möglich)',
    options: [
      'Messe/ Event',
      'Showroom',
      'Onlinetool (Webversion)',
      'Unterstützung Vertrieb',
      'Sonstiges'
    ],
    multiple: true,
    selected: []
  },
  {
    question: 'Unsere Lösungen garantieren mehr Wirkung im Marketing und Sales. Worum geht es ihnen im Detail? (Mehrfachauswahl möglich)',
    options: [
      'Produkte besser verstehen',
      '(Visuelles) Storytelling verbessern',
      'Komplexe Prozesse interaktiv erklären',
      'Innovative Customer Journey',
      'Ganzheitliche digitale Markeninszenierung',
      'Kauf oder Miete von Multitouch-Systemen (Hardware)',
      'Sonstiges'
    ],
    multiple: true,
    selected: []
  },
  {
    question: 'Wann wünschen Sie sich die Umsetzung?',
    options: [
      'so schnell wie möglich',
      'innerhalb von 6 Monaten',
      '6-12 Monate',
      'steht noch nicht fest'
    ],
    selected: []
  },
  {
    question: 'Jetzt nur noch Ihre E-Mail Adresse.',
    showForm: true,
    inputs: [
      {
        name: 'E-Mail*',
        type: 'email',
        value: null
      }
    ]
  },
  {
    question: 'Damit wir Sie beraten können.',
    showForm: true,
    inputs: [
      {
        name: 'Vor- und Nachname*',
        type: 'text',
        value: null
      },
      {
        name: 'Unternehmen',
        type: 'text',
        value: null
      },
      {
        name: 'Telefon',
        type: 'tel',
        value: null
      },
      {
        name: 'Nachricht',
        type: 'textarea',
        value: null
      }
    ]
  },
  {
    question: 'Wie wünschen Sie sich die Kontaktaufnahme?',
    contactOption: true,
    options: [
      'Expertentermin (Teams) vereinbaren',
      'telefonischer Anruf',
      'E-Mail'
    ],
    selected: []
  }
])

const currentQuestion    = ref(0)
const finished           = ref(false)
const getCurrentQuestion = computed(() => questions.value[currentQuestion.value])

const handleCalendly = () => {
  Calendly.initPopupWidget({
    url: 'https://calendly.com/touchwert_webapp/kennenlerngespraech-30-minuten?hide_gdpr_banner=1'
  })
}

const setAnswer = e => {
  questions.value[currentQuestion.value].selected === e.target.value
  e.target.value = null
}

const previousQuestion = () => {
  if (currentQuestion.value > 0) currentQuestion.value--
}

const nextQuestion = () => {
  if (currentQuestion.value < questions.value.length - 1) currentQuestion.value++
  else {
    finished.value = true
    let payload    = '\n\n'

    questions.value.forEach(item => {
      payload += item.question
      if (item.options) item.selected.forEach(idx => payload += '\n- ' + item.options[idx])
      if (item.inputs)  item.inputs.forEach(  elm => payload += '\n- ' + elm.name + ': ' + elm.value)
      payload += '\n\n'

      // show optional calendly widget -> idx 0 = 'Expertentermin (Teams) vereinbaren'
      if (item.contactOption && item.selected.includes(0)) handleCalendly()
    })

    // Send data to backend
    fetch('/request/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data)
    })
    .catch(error => {
      console.error('Error:', error)
    })

    setTimeout(() => {
      currentQuestion.value = 0
      finished.value = false
      questions.value.forEach(question => {
        if (question.selected) question.selected = []
        if (question.inputs)   question.inputs.forEach(input => input.value = null)
      })
    }, 5000)
  }
}

onMounted(() => {
  // set style width attribute to prevent width glitch while animating
  const reqForm       = document.getElementById('request-form')
  reqForm.style.width = `${reqForm.clientWidth}px`
})
</script>


<template>
  <section class="content mt-5">
    <div
        class="container"
        id="request-form">
      <h2 class="text-center">
        <small>#get-in-touch</small>
        Wie können wir Sie unterstützen?
      </h2>
      <Transition name="bounceRight">
        <section
            class="mt-5 request"
            :key="`step-${currentQuestion}-${finished}`">
          <div
              class="question"
              v-if="getCurrentQuestion.question && !finished">
            <h5>{{ getCurrentQuestion.question }}</h5>
          </div>
          <div
              class="options"
              v-if="getCurrentQuestion.options && !finished">
            <label
                v-for="(option, index) in getCurrentQuestion.options"
                :class="{ active: getCurrentQuestion.selected.includes(index) }">
              <input
                  class="form-check"
                  type="checkbox"
                  :value="index"
                  v-model="getCurrentQuestion.selected"
                  :disabled="
                    !getCurrentQuestion.multiple &&
                    getCurrentQuestion.selected.length > 0 &&
                    !getCurrentQuestion.selected.includes(index)"
                  @change="setAnswer" />
              {{ option }}
            </label>
          </div>
          <div
              class="form"
              v-if="getCurrentQuestion.showForm && !finished">
            <template v-for="input in getCurrentQuestion.inputs">
              <input
                  v-if="input.type !== 'textarea'"
                  class="form-input"
                  :type="input.type"
                  :placeholder="input.name"
                  v-model="input.value" />
              <textarea
                  v-if="input.type === 'textarea'"
                  class="form-input"
                  :placeholder="input.name"
                  v-model="input.value" />
            </template>
            <p class="privacy-note">
              <b>🔒 Datenschutz:</b>
              Wir behandeln Ihre Daten streng vertraulich, Ihre Daten werden
              niemals an Dritte weitergegeben. Ihre Daten werden mittels
              SSL-Zertifikat verschlüsselt gesendet.
            </p>
          </div>
          <div class="buttons" v-if="!finished">
            <button
                :disabled="currentQuestion === 0"
                @click="previousQuestion"
                class="btn btn-outline">zurück</button>
            <button
                :disabled="
                  questions[currentQuestion].selected &&
                  questions[currentQuestion].selected.length === 0"
                @click="nextQuestion"
                class="btn"
                :class="
                  currentQuestion === questions.length - 1
                    ? 'btn-primary'
                    : 'btn-outline'">
              <span v-if="currentQuestion < questions.length - 1">
                weiter
              </span>
              <span v-if="currentQuestion === questions.length - 1">
                Jetzt unverbindlich Kontakt aufnehmen
              </span>
            </button>
          </div>
          <div
              class="msg msg-contact-request"
              v-if="finished">
            Vielen Dank für Ihre Anfrage!
          </div>
        </section>
      </Transition>
    </div>
  </section>
</template>


<style lang="scss">
.bounceRight-leave-active {
  position: absolute;
  width: calc(100% - 5rem);
}

#request-form {
  min-height: 100vh
}
</style>
