<template>
  <div class="global">
    <div class="custom-cursor">
      <div id="cursor-big" class="custom-cursor__ball custom-cursor__ball--big">
        <span class="hover-text"></span>
      </div>
    </div>
    <div class="overlay" />
  </div>
</template>


<script setup>
import { onMounted, ref } from 'vue'
import { gsap } from 'gsap'
import { Overlay } from './overlay'

const scrollpos = ref(0)

onMounted(() => {
  const cursorBig      = document.getElementById('cursor-big'),
        links          = document.getElementsByTagName('a'),
        withClassHover = document.getElementsByClassName('cursor-hover'),
        withHover      = [...links, ...withClassHover]

  // Event Handlers
  const onMouseMove = (e) => {
    gsap.to(cursorBig, {
      x: e.clientX,
      y: e.clientY,
      duration: 0
    })
  }

  const onMouseHover = (e) => {
    const text = e.target.dataset.hover || 'mehr →'
    cursorBig.childNodes[0].innerHTML = text
    cursorBig.classList.add('active')
  }

  const onMouseHoverOut = () => {
    cursorBig.childNodes[0].innerHTML = null
    cursorBig.classList.remove('active')
  }

  // Get the root element
  const r = document.querySelector(':root')

  // Event Listeners
  document.addEventListener('scroll', () => {
    scrollpos.value = window.scrollY || document.documentElement.scrollTop
    scrollpos.value = scrollpos.value * 0.001 + 1

    if (scrollpos.value >= 1.30) document.body.classList.add('scrolled')
    else document.body.classList.remove('scrolled')

    //if (scrollpos.value <= 1.00) scrollpos.value = 1.00
    //if (scrollpos.value >= 1.30) scrollpos.value = 1.30
    //r.style.setProperty('--header-scale', scrollpos.value)
    //r.style.setProperty('--header-blur', `${(scrollpos.value - 1) * 50}px`)
  })


  document.addEventListener('mousemove', onMouseMove)
  //document.addEventListener('mousedown', onMouseHover)
  document.addEventListener('mouseup', onMouseHoverOut)

  withHover.forEach((element) => {
    element.addEventListener('mouseover', onMouseHover)
    element.addEventListener('mouseout', onMouseHoverOut)
  })


  const header = document.querySelector('header.default')
  if (header) {
    header.addEventListener('click', () => {
      const scrollDistance = window.innerHeight/2
      window.scrollBy({ top: scrollDistance, behavior: 'smooth' })
    })
  }


  // Select the overlay element from the DOM
  const overlayEl = document.querySelector('.overlay')

  // Instantiate an Overlay object using the selected overlay element
  const overlay = new Overlay(overlayEl, {
    rows: 9,
    columns: 16
  })

  let isAnimating = false

  // Main Menu
  const menu  = document.querySelector('#menu')
  const menuMain  = document.querySelector('#menu-main')
  const close = document.querySelector('#close')

  menu.addEventListener('click', () => {
    if (isAnimating) return
    isAnimating = true

    overlay.show({
      duration: 0.25,        // Duration for each cell animation
      ease: 'power1.inOut', // Ease for each cell animation
      stagger: {
        grid: [overlay.options.rows, overlay.options.columns],
        from: 'end',
        each: 0.025
      }
    }).then(() => {
      // show the nav
      menuMain.classList.remove('hidden')

      // Now hide the overlay
      overlay.hide({
        // Duration for each cell animation
        duration: 0.25,
        // Ease for each cell animation
        ease: 'power3',
        // Stagger object
        stagger: {
          grid: [overlay.options.rows, overlay.options.columns],
          from: 'end',
          each: 0.025
        }
      }).then(() => isAnimating = false)
    })
  })

  close.addEventListener('click', () => {
    if (isAnimating) return
    isAnimating = true

    gsap.to(menuMain, {
      duration: 0.5,
      ease: 'power2.in',
      scale: 1.35
    })

    overlay.show({
      // Duration for each cell animation
      duration: 0.25,
      // Ease for each cell animation
      ease: 'power1.inOut',
      // Stagger object
      stagger: {
        grid: [overlay.options.rows, overlay.options.columns],
        from: 'start',
        each: 0.025
      }
    }).then(() => {
      // show the nav
      menuMain.classList.add('hidden')

      gsap.to(menuMain, {
        duration: 0.5,
        ease: 'expo',
        scale: 1
      })

      // Now hide the overlay
      overlay.hide({
        // Duration for each cell animation
        duration: 0.25,
        // Ease for each cell animation
        ease: 'power3',
        // Stagger object
        stagger: {
          grid: [overlay.options.rows, overlay.options.columns],
          from: 'start',
          each: 0.025
        }
      }).then(() => isAnimating = false)
    })
  })
})
</script>


<style lang="scss">
.custom-cursor__ball {
  position: fixed;
  top: 0;
  left: 0;
  //mix-blend-mode: difference;
  z-index: 99999;
  pointer-events: none;
  transform: translate(-50%, -50%);
  width: 1rem;
  height: 1rem;

  span {
    opacity: 0;
    position: absolute;
    bottom: -2.5rem;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 600;
    transition: all ease-in-out 500ms;
    white-space: nowrap;
    color: #fff;
  }

  &.active {
    span {
      opacity: 1;
    }
  }
}

.overlay {
  --columns: 20;
  grid-area: 1 / 1 / -1 / -1;
  grid-template-columns: repeat(var(--columns), 1fr);
  display: grid;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  pointer-events: none;
  opacity: 0;
}

.overlay-cell {
  background-color: #5000f1;
  box-shadow: 0 0 0 1px rgba(#fff, 0.15);
}
</style>
